#zPage {
    // background-color: blue;

    // background-color: ${theme.colors.c1l4};
    // background-color: lime;
    // height: 100%;
    // min-height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0px !important;
    width: 100% !important;
    .btn {
        cursor: pointer;
    }
    .row {
        margin: 0px !important;
    }
    //  padding-top: 270px;
}
